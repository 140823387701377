import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = ({ img, text }) => {
  const { title, subTitle } = text || {};

  return (
    <section
      className="hero d-grid align-items-center"
    >
      <GatsbyImage
        image={img}
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: height,
          height: "60vh",
          objectFit: "cover",
          objectPosition: "center",
        }}
        // You can optionally force an aspect ratio for the generated image
        aspectratio={3 / 1}
        // This is a presentational image, so the alt should be an empty string
        formats={["auto", "webp", "avif"]}
        alt="Hero Image"
      />
      {(title || subTitle) && (
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: "1/1",
            position: "relative",
            // This centers the other elements inside the hero component
            placeItems: "center",
            display: "grid",
            margin: "0 2em",
          }}
        >
          {/* Any content here will be centered in the component */}
          {title && (
            <h1
              className="fw-bold"
              style={{
                backgroundColor: "rgba(218, 165, 32, 0.65)",
                color: "white",
                lineHeight: "1",
                padding: "0.5em",
                paddingRight: "1em",
                textShadow: "2px 4px black",
                textAlign: "center",
                borderTopRightRadius: "2em",
                fontSize: "2.5rem",
              }}
            >
              {title}
            </h1>
          )}
          {subTitle && (
            <h3
              className="fw-bold"
              style={{
                backgroundColor: "rgba(218, 165, 32, 0.65)",
                color: "white",
                lineHeight: "1",
                padding: "1.2rem",
                textShadow: "2px 3px black",
                textAlign: "center",
                marginRight: "2em",
                marginLeft: "2em",
                borderBottomLeftRadius: "1em",
                fontSize: "1.5rem",
              }}
            >
              {subTitle}
            </h3>
          )}
          {/* {isHomePage && <button>CTA</button>} */}
        </div>
      )}
    </section>
  );
};

export default Hero;
