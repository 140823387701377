import * as React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";

import "./Footer.css";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer pt-4 pb-2 pb-lg-5 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <ul className="list-unstyled">
                <li>
                  <Link
                    to="/about/"
                    className="navbar-item text-decoration-none"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className="navbar-item text-decoration-none"
                    to="/practice-areas/"
                  >
                    Practice Areas
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4">
              <ul className="list-unstyled">
                <li>
                  <Link
                    className="navbar-item text-decoration-none"
                    to="/blogs/"
                  >
                    Latest Stories
                  </Link>
                </li>
                <li>
                  <Link
                    className="navbar-item text-decoration-none"
                    to="/contact/"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              <div className="social text-center mb-4 mb-lg-0">
                <a title="facebook" href="https://facebook.com">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="instagram" href="https://instagram.com">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-4 footer-logo text-center">
              <img
                src={logo}
                alt="Kingsbench logo"
                style={{ width: "6em", maxHeight: "initial" }}
              />
              <h2
                style={{
                  margin: "0.5em",
                  fontSize: "1em !important",
                  fontWeight: "normal",
                  color: "rgba(218, 165, 32, 0.67)",
                }}
              >
                Providing legal solutions
              </h2>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
