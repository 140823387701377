import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import logo from "../img/logo.png";
// import "./Navbar.css";

const Navbar = () => {
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [navMenuOpenClass, setNavMenuOpenClass] = useState("");

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 992) {
        setNavMenuOpen(false);
      }
    });
  }, []);

  const toggleNavMenu = () => {
    setNavMenuOpen((navMenuOpen) => !navMenuOpen);
  };
  useEffect(() => {
    navMenuOpen ? setNavMenuOpenClass("show") : setNavMenuOpenClass("");

    return () => setNavMenuOpenClass("");
  }, [navMenuOpen]);

  return (
    <header className="main-header">
      <nav
        className="navbar navbar-expand-lg py-0"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container py-0 align-items-center position-relative">
          <Link to="/" className="navbar-brand" title="Logo">
            <img src={logo} alt="Kingsbench logo" />
          </Link>
          <div className="navmenu-wrapper">
            <menu
              id="navmenu"
              className={`navmenu navbar-collapse collapse ${navMenuOpenClass} text-center ms-lg-auto navbar-item-list container p-0 m-0`}
            >
              <li className="navmenu-item d-lg-inline-block">
                <Link
                  className="d-block d-lg-inline-block d-lg-initial px-3 py-2 text-decoration-none"
                  to="/"
                  activeClassName="navmenu-item-link-active"
                  onClick={toggleNavMenu}
                >
                  Home
                </Link>
              </li>
              <li className="navmenu-item d-lg-inline-block">
                <Link
                  className="d-block d-lg-inline-block d-lg-initial px-3 py-2 text-decoration-none ms-lg-1"
                  to="/about/"
                  activeClassName="navmenu-item-link-active"
                  onClick={toggleNavMenu}
                >
                  About
                </Link>
              </li>
              <li className="navmenu-item d-lg-inline-block">
                <Link
                  className="d-block d-lg-inline-block d-lg-initial px-3 py-2 text-decoration-none ms-lg-1"
                  to="/practice-areas/"
                  activeClassName="navmenu-item-link-active"
                  onClick={toggleNavMenu}
                >
                  Practice Areas
                </Link>
              </li>
              <li className="navmenu-item d-lg-inline-block">
                <Link
                  className="d-block d-lg-inline-block d-lg-initial px-3 py-2 text-decoration-none ms-lg-1"
                  to="/blogs/"
                  activeClassName="navmenu-item-link-active"
                  onClick={toggleNavMenu}
                  partiallyActive={true}
                >
                  Blogs
                </Link>
              </li>
              <li className="navmenu-item d-lg-inline-block">
                <Link
                  className="d-block d-lg-inline-block d-lg-initial px-3 py-2 text-decoration-none ms-lg-1"
                  to="/contact/"
                  activeClassName="navmenu-item-link-active"
                  onClick={toggleNavMenu}
                >
                  Contact
                </Link>
              </li>
            </menu>
          </div>

          <button
            className={`navmenu-disclosure-widget ${navMenuOpenClass} d-block d-lg-none position-relative border-0`}
            data-target="navmenu"
            tabIndex={0}
            onClick={toggleNavMenu}
          >
            <span className="d-block" />
            <span className="d-block" />
            <span className="d-block" />
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
