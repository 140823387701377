import * as React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Hero from "components/Hero";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import { useMyAppWideContext } from "context/my-app-wide-context";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const TemplateWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const data = useStaticQuery(graphql`
    query heroQuery {
      heroImage: file(name: { eq: "wooden-judge-gavel" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      markdownRemark(frontmatter: { type: { eq: "business-information" } }) {
        frontmatter {
          heroText {
            pathname
            subTitle
            title
          }
        }
      }
    }
  `);

  const heroImage = data.heroImage.childImageSharp.gatsbyImageData;
  const heroDetails = data.markdownRemark.frontmatter.heroText;
  let currentHeroText = {};
  currentHeroText = heroDetails.find((item) =>
    pathname.includes("blog")
      ? item.pathname === "/blogs/"
      : item.pathname === pathname
  );

  const { title: siteTitle, description } = useSiteMetadata();
  const context = useMyAppWideContext();
  let setCurrentPath = {};
  let currentPath = {};
  if (context) setCurrentPath = context.setCurrentPath;
  if (context) currentPath = context.currentPath;

  useEffect(() => {
    let pageTitle =
      pathname === "/"
        ? "Home"
        : pathname[1].toUpperCase() + pathname.slice(2, -1);
    if (pageTitle.includes("blogs") || pageTitle.includes("Blogs"))
      pageTitle = "Blogs";
    setCurrentPath(pageTitle);

    // heroText = heroDetails.find((item) => item.pathname === pathname);
    // console.log(heroText);
  }, [pathname]);

  return (
    <>
      <Helmet titleTemplate={`${siteTitle} - ${currentPath}`}>
        <html lang="en" />
        <title>{siteTitle}</title>
        <meta name="description" content={description} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>
      <Navbar />
      <Hero img={heroImage} text={currentHeroText} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default TemplateWrapper;
